import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 500.000000 500.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" transform="translate(0.000000,500.000000) scale(0.100000,-0.100000)">
          <path d="M2190 3794 c-221 -41 -411 -134 -562 -276 -51 -47 -58 -59 -58 -91 0
-53 35 -87 89 -87 33 0 47 7 88 45 108 102 277 190 422 221 101 22 282 21 386
-1 147 -31 300 -108 413 -209 54 -48 68 -56 103 -56 52 0 89 37 89 90 0 52
-175 195 -333 271 -152 73 -237 91 -437 94 -96 2 -186 1 -200 -1z"/>
<path d="M2204 3466 c-82 -19 -188 -62 -252 -103 -72 -45 -149 -118 -156 -148
-10 -38 10 -74 48 -90 43 -18 64 -11 130 44 60 51 171 106 256 127 87 22 241
15 327 -16 68 -24 170 -85 219 -132 64 -60 154 -29 154 54 0 34 -6 42 -64 90
-156 129 -282 178 -476 184 -84 2 -145 -1 -186 -10z"/>
<path d="M2264 3165 c-78 -17 -154 -56 -210 -107 -53 -49 -60 -93 -19 -133 35
-36 70 -32 143 19 130 91 269 86 392 -15 51 -42 115 -34 139 18 19 43 12 65
-35 109 -57 55 -149 98 -235 113 -80 13 -97 13 -175 -4z"/>
<path d="M2314 2822 c-17 -11 -36 -34 -43 -51 -40 -96 85 -176 160 -102 39 39
40 89 2 131 -36 39 -78 47 -119 22z"/>
<path d="M2166 2513 c-4 -5 -152 -10 -329 -13 -299 -5 -324 -6 -356 -25 -51
-30 -76 -69 -76 -120 0 -28 6 -50 16 -61 16 -15 16 -13 12 29 -4 35 -1 53 16
80 32 53 66 62 226 62 l140 0 3 -117 3 -118 34 0 34 0 3 118 3 117 104 2 c123
1 156 -8 170 -48 16 -45 22 -35 18 31 -4 62 -8 75 -21 63z"/>
<path d="M2897 2480 c-115 -30 -209 -117 -242 -227 -6 -21 -3 -23 27 -23 32 0
35 3 47 48 16 60 109 155 167 171 124 35 279 -14 314 -99 7 -17 16 -30 20 -30
14 0 -11 105 -28 118 -47 36 -229 61 -305 42z"/>
<path d="M2328 2411 c-76 -25 -138 -97 -138 -160 0 -18 5 -22 28 -19 23 3 27
8 30 41 2 27 13 47 37 71 76 77 205 47 233 -54 9 -32 10 -28 11 36 l1 71 -42
6 c-98 14 -134 16 -160 8z"/>
<path d="M1485 2160 c3 -5 13 -10 21 -10 10 0 14 -13 14 -46 0 -27 4 -43 10
-39 6 3 10 24 10 46 0 28 4 39 15 39 8 0 15 5 15 10 0 6 -21 10 -46 10 -27 0
-43 -4 -39 -10z"/>
<path d="M1690 2115 c0 -30 5 -55 10 -55 6 0 10 11 10 25 0 20 5 25 25 25 20
0 25 -5 25 -25 0 -14 5 -25 10 -25 6 0 10 25 10 55 0 30 -4 55 -10 55 -5 0
-10 -9 -10 -20 0 -15 -7 -20 -25 -20 -18 0 -25 5 -25 20 0 11 -4 20 -10 20 -5
0 -10 -25 -10 -55z"/>
<path d="M1914 2156 c-3 -8 -4 -31 -2 -52 3 -36 6 -39 36 -42 18 -2 32 1 32 7
0 6 -11 11 -25 11 -16 0 -25 6 -25 15 0 9 10 15 26 15 14 0 23 4 19 10 -3 6
-15 10 -26 10 -10 0 -19 5 -19 10 0 6 11 10 25 10 14 0 25 5 25 10 0 15 -60
12 -66 -4z"/>
<path d="M2267 2164 c-15 -16 -6 -45 18 -56 36 -16 31 -31 -7 -22 -29 6 -31 5
-19 -10 16 -20 45 -21 60 -2 15 18 3 46 -20 46 -11 0 -19 7 -19 16 0 12 6 15
21 11 13 -3 19 -1 16 6 -4 14 -39 21 -50 11z"/>
<path d="M2460 2126 c0 -51 18 -71 54 -62 19 5 27 15 32 41 3 19 3 43 -2 52
-6 15 -8 14 -11 -7 -8 -59 -14 -70 -33 -70 -17 0 -20 7 -20 45 0 25 -4 45 -10
45 -5 0 -10 -20 -10 -44z"/>
<path d="M2680 2115 c0 -54 1 -55 29 -55 35 0 48 15 46 56 -2 43 -12 54 -46
54 -29 0 -29 -1 -29 -55z m50 25 c0 -5 -7 -10 -15 -10 -8 0 -15 5 -15 10 0 6
7 10 15 10 8 0 15 -4 15 -10z m8 -47 c2 -8 -5 -13 -17 -13 -12 0 -21 6 -21 16
0 18 31 15 38 -3z"/>
<path d="M3045 2147 c-31 -48 9 -103 60 -83 27 10 16 26 -15 20 -23 -5 -31 -2
-36 11 -11 26 10 50 41 48 35 -3 28 21 -8 25 -18 2 -30 -4 -42 -21z"/>
<path d="M3248 2119 c-3 -53 6 -75 16 -36 l6 22 15 -22 c9 -13 23 -23 31 -23
19 0 18 10 -2 25 -14 10 -14 15 -3 32 16 27 -2 53 -36 53 -21 0 -23 -6 -27
-51z m50 14 c2 -8 -5 -13 -17 -13 -12 0 -21 6 -21 16 0 18 31 15 38 -3z"/>
<path d="M3450 2115 c0 -30 5 -55 10 -55 6 0 10 25 10 55 0 30 -4 55 -10 55
-5 0 -10 -25 -10 -55z"/>
<path d="M3608 2126 c-4 -58 -1 -66 25 -66 36 0 45 13 44 60 -2 43 -4 45 -33
48 -31 3 -32 2 -36 -42z m52 14 c0 -5 -9 -10 -20 -10 -11 0 -20 5 -20 10 0 6
9 10 20 10 11 0 20 -4 20 -10z m0 -45 c0 -8 -9 -15 -20 -15 -11 0 -20 7 -20
15 0 8 9 15 20 15 11 0 20 -7 20 -15z"/>
<path d="M1820 1880 c0 -129 -9 -155 -59 -168 -28 -8 -8 -10 94 -10 96 0 122
2 98 9 -54 16 -63 39 -63 166 l0 113 -35 0 -35 0 0 -110z"/>
<path d="M2395 1968 c71 -50 141 -133 154 -181 29 -103 -10 -196 -100 -241
-165 -82 -349 38 -325 211 3 25 17 66 31 90 13 25 19 43 12 40 -20 -6 -60 -95
-65 -146 -9 -100 41 -186 136 -233 70 -34 188 -32 258 4 92 48 136 121 136
223 -1 78 -28 140 -90 204 l-48 51 -65 0 -64 -1 30 -21z"/>
<path d="M2711 1960 c27 -36 77 -72 144 -101 73 -33 218 -33 292 0 49 22 133
98 133 121 0 17 -7 12 -57 -38 -59 -58 -120 -82 -213 -82 -93 0 -154 24 -213
82 -36 36 -55 48 -78 48 l-30 0 22 -30z"/>
<path d="M990 1381 c0 -17 6 -33 13 -35 6 -2 482 -9 1057 -15 575 -6 1186 -14
1358 -18 l312 -6 0 36 0 37 -187 0 c-104 0 -474 5 -823 10 -349 5 -881 12
-1182 16 l-548 7 0 -32z"/>
<path d="M990 1223 l0 -31 133 -6 c188 -8 2590 -35 2599 -30 13 8 9 54 -4 58
-7 3 -456 9 -998 15 -542 6 -1153 14 -1357 18 l-373 6 0 -30z"/>

        </g>
      </svg>
    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
